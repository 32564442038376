// @ts-check
/**
 * BE CAREFUL WHEN ADDING IMPORTS TO THIS FILE!
 *
 * The bugsnag chunk is supposed to run even if everything else fails.
 * Adding dependencies could cause it to depend on other chunks (eg. index or
 * vendor) so that bugsnag doesn't work if the chunk fails to load.
 *
 * Check the splitChunks section in the webpack config and make sure that
 * anything this file needs gets split into the bugsnag chunk.
 */
import Bugsnag from '@bugsnag/js'
import bugsnagConfig from '../config/bugsnag'

const localDevelopmentConfig = {}

if (bugsnagConfig.apiKey === 'empty') {
  localDevelopmentConfig.logger = {
    info: () => {},
    warn: () => {},
    error: () => {},
    debug: () => {},
  }
}

// @ts-expect-error TS(2345): Argument of type '{ onError: (event: Event) => boo... Remove this comment to see the full error message
const bugsnag = Bugsnag.createClient({
  ...bugsnagConfig,
  ...localDevelopmentConfig,
  onError: event => {
    // this happens on webOS devices on every start, can't be caught, and has no effect on the functionality of the app
    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (
      event.errors.every(
        error =>
          error.errorMessage?.indexOf(
            'window.getWinOwnerAppId is not a function',
          ) >= 0,
      )
    ) {
      return false
    }

    // report all other errors by default
    return true
  },
})

export default bugsnag
