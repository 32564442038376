import React from 'react'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const bugsnagConfig = {
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  endpoints: {
    notify: process.env.REACT_APP_BUGSNAG_ENDPOINT_NOTIFY,
    sessions: process.env.REACT_APP_BUGSNAG_ENDPOINT_SESSIONS,
  },
  collectUserIp: false,
  appVersion: process.env.APP_VERSION,
  releaseStage: process.env.RELEASE_STAGE,
  enabledReleaseStages: ['dev', 'preprod', 'prod'],
  metadata: {
    portal: process.env.REACT_APP_PORTAL,
  },
  plugins: [new BugsnagPluginReact(React)],
}

export default bugsnagConfig
